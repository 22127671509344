import React from "react"
import { graphql } from "gatsby"

//components
import Layout from "../components/Layout"

//constant

const CommercantTemplate = ({
  data: {
    com: { title, node_locale },
    lang: { nodes },
  },
}) => {
  const langSelect = [
    { locale: nodes[0].node_locale, path: "/commercants/" + nodes[0].slug },
    { locale: nodes[1].node_locale, path: "/handelaren/" + nodes[1].slug },
  ]
  const langCurrent = node_locale

  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <div>{title}</div>
    </Layout>
  )
}
export const query = graphql`
  query GetOneCommercant($slug: String, $lang: String) {
    com: contentfulCommercant(id: { eq: $slug }) {
      title
      node_locale
      slug
      contentful_id
    }
    lang: allContentfulCommercant(filter: { contentful_id: { eq: $lang } }) {
      nodes {
        node_locale
        slug
      }
    }
  }
`

export default CommercantTemplate
